import { useRef } from "react";

const GoodCb = () => true;
const ErrorCb = () => false;

function getPlayedTime(video) {
  let totalPlayed = 0;
  const played = video.played;
  for (let i = 0; i < played.length; i++) {
    totalPlayed += played.end(i) - played.start(i);
  }
  return {
    total: totalPlayed,
    percent: (totalPlayed / video.duration) * 100,
  };
}

export const useInforming = ({ options, duration, mobile }) => {
  const { config } = options;
  const eventsAPI = config.eventsAPI;
  const counterStart = useRef(false);
  const videoStarted = useRef(false);

  const _duration = useRef(duration.current);
  const timeStarted = useRef(-1);
  const timePlayed = useRef(0);

  const EndCallback = async ({ good, error, url }) => {
    try {
      const response = await fetch(url);
      await good(options.id, response);
    } catch (e) {
      await error(options.id, e);
    }
  };

  function videoStartingPlaying() {
    // запомнить время, когда пользователь начал просмотр видео
    timeStarted.current = new Date().getTime() / 1000;
    _duration.current = duration.current;
  }

  async function videoStoppedPlaying(event, { video }) {
    const url = config.eventsAPI.ended.url;
    const good = config.eventsAPI.ended.good || GoodCb;
    const error = config.eventsAPI.ended.error || ErrorCb;

    // Время начала меньше нуля означает, что событие остановки было запущено vidout start event
    if (timeStarted.current > 0) {
      const playedFor = new Date().getTime() / 1000 - timeStarted.current;
      timeStarted.current = -1;
      // добавить новое количество сыгранных секунд
      timePlayed.current += playedFor;
    }

    // Считается завершенным только в том случае, если был достигнут конец видеоролика
    if (url && config?.informing && event.type === "ended") {
      const result = getPlayedTime(video);
      if (Math.round(result.percent) >= 95) {
        await EndCallback({ good, error, url });
        return true;
      }
      if (Math.round(timePlayed.current) >= Math.round(_duration.current)) {
        await EndCallback({ good, error, url });
      }
    }
  }

  function handlePlaying(event) {
    videoStartingPlaying();
  }

  const handleVideoPlay = async (event) => {
    videoStartingPlaying();
    const urlCounter = config.eventsAPI.counter.url;
    if (!counterStart.current && urlCounter) {
      const goodCounter = eventsAPI.counter.good || GoodCb;
      const errorCounter = eventsAPI.counter.error || ErrorCb;
      if (urlCounter) {
        try {
          const result = await fetch(urlCounter);
          goodCounter(options.id, result);
        } catch (e) {
          console.warn("Ошибка при urlCounter", e);
          errorCounter(options.id, e);
        }
        counterStart.current = true;
      }
    }
    if (config.informing && !videoStarted.current) {
      videoStarted.current = true;
      const url = config.eventsAPI.play.url;
      const good = config.eventsAPI.play.good || GoodCb;
      const error = config.eventsAPI.play.error || ErrorCb;
      if (url) {
        try {
          const response = await fetch(url);
          good(options.id, response);
        } catch (e) {
          error(options.id, e);
        }
      }
    }
  };

  const handleVideoPause = async (event, other) => {
    videoStoppedPlaying(event, other);
  };

  const handleVideoEnd = async (event, other) => {
    videoStoppedPlaying(event, other);
  };

  return { handleVideoPlay, handleVideoPause, handleVideoEnd, handlePlaying };
};
