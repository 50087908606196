export const getQualitiesFromMPD = async (mpd) => {
  try {
    if (mpd?.mPD) {
      const qualityArr = mpd.mPD.period.adaptationSet[0].representation;
      return qualityArr;
    }
  } catch (e) {
    console.error("Не удалось извлечь массив качеств из манифеста");
    throw new Error(e);
  }
};
