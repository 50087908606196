import { mpdParser } from "mpd-m3u8-to-json";

export const parseMPD = async (manifestUri) => {
  try {
    const res = await fetch(manifestUri);
    const manifestText = await res.text();
    const startMDP = manifestText.indexOf("<MPD");
    const manifest = manifestText.slice(startMDP, manifestText.length);
    const result = mpdParser(manifest);
    return result;
  } catch (e) {
    console.error("Ошибка парсинга манифеста", e);
    throw new Error(e);
  }
};
