//import { HTMLPlayer } from "./PlayerVime";
import { HTMLPlayer } from "./PlayerShaka";
// import { HTMLPlayer } from "./Player";
//import { HTMLPlayer } from "./PlayerVideoJS";
import React from "react";
import ReactDOM from "react-dom";
//const src = new URL("/source/ffmpeg/master.m3u8", import.meta.url);
/*import src from "/source/ffmpeg/master.m3u8";
console.log("1.0.1", src);*/

const DEFAULT_OPTIONS = {
  eventsAPI: {
    counter: {
      url: "",
      good: function (id, response) {
        //console.log(id, "counter good");
      },
      error: function (id, e) {
        //console.log(id, "counter error");
        return true;
      },
    },
    play: {
      url: "",
      good: function (id, response) {
        //console.log(id, "play good");
      },
      error: function (id, e) {
        //console.log("play error", id);
      },
    },
    ended: {
      url: "",
      good: function (id, response) {
        //console.log(id, "end good");
      },
      error: function (id, e) {
        //console.log(" end error ", id);
      },
    },
  },
};

export function html5player(container, options) {
  const defaultsOptions = html5player?.defaults || {};
  let _options = {
    ...options,
    container,
    config: { ...DEFAULT_OPTIONS, ...defaultsOptions },
  };
  /*try {
    _options = proxy({ ...defaultsOptions, ...options });
  } catch (e) {
    _options = { ...defaultsOptions, ...options };
  }*/

  if (_options?.source?.sources[0]) {
    ReactDOM.render(<HTMLPlayer options={_options} />, container);
  }

  const version = () => {
    console.log("1.0.1");
  };

  const refresh = (config) => {
    _options = config;
    ReactDOM.render(<HTMLPlayer options={{ ..._options }} />, container);
  };

  return { ..._options, refresh, version };
}

window.html5player = html5player;

if (!html5player?.defaults) {
  window.html5player.defaults = {};
  html5player.defaults = {};
}
