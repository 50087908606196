//import ky from "ky";
export const getHLSlink = async (qualityArr, manifestUri, hasAudio) => {
  const _qualityArr = qualityArr.map((item) => `video_${item.width}.mp4`);
  return `${manifestUri.split("/").slice(0, -1).join("/")}/,${_qualityArr.join(
    ","
  )}${hasAudio ? ",audio.mp4" : ""},.urlset/master-v1-a1.m3u8`;

  //                                let url = `${this.dashSource[0].split('/').slice(0, -1).join('/')}/,${sizesUrl.join(',')}${audio ? ',audio.mp4' : ''},.urlset/master-v1-a1.m3u8`;
  /*const parsedManifest = parse(manifest, { manifestUri });
  console.log(parsedManifest);*/
  /*const response = await ky.get(manifestUri).json();
  return response;*/
  /*let sizes = response.data
    .match(/\swidth="(\d+)"/g)
    .join("")
    .match(/\d+/g)
    .map((i) => Number(i));
  let options = { ignoreComment: true, alwaysChildren: true };
  let result1 = convert.xml2js(response.data, options);
  let video = result1.elements[0].elements[1].elements[0];
  let audio = result1.elements[0].elements[1].elements[1];*/
};
