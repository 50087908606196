import nearestNormalAspectRatio from "nearest-normal-aspect-ratio";

/*function gcd(u, v) {
  if (u === v) return u;
  if (u === 0) return v;
  if (v === 0) return u;

  if (~u & 1)
    if (v & 1) return gcd(u >> 1, v);
    else return gcd(u >> 1, v >> 1) << 1;

  if (~v & 1) return gcd(u, v >> 1);

  if (u > v) return gcd((u - v) >> 1, v);

  return gcd((v - u) >> 1, u);
}

function ratio(w, h) {
  const d = gcd(w, h);
  return [w / d, h / d];
}*/

export const getAspectRatio = ({ width, height }) => {
  return nearestNormalAspectRatio(width, height);
  //ratio(width, height).join(":");
};
