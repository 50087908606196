import shaka from "shaka-player/dist/shaka-player.ui";
import "shaka-player/dist/controls.css";
import "./shakaTheme/themeYoutube.css";
import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useLayoutEffect,
  Fragment,
} from "react";
import { parseMPD } from "./utils/parseMPD";
import { getQualitiesFromMPD } from "./utils/getQualitiesFromMPD";
import { videoHasAudio } from "./utils/videoHasAudio";
import { getHLSlink } from "./utils/getHLSlink";
import { getAspectRatio } from "./utils/getApectRatio";
import { getPosterUrl } from "./utils/getPosterUrl";
import useMobileDetect from "use-mobile-detect-hook";
import { useInforming } from "./hooks/useInforming";
import styled, { css } from "styled-components";
import { MobileMute } from "./shakaComponents/MobileMute";
import useSize from "@react-hook/size";

const Wrapper = styled.div`
  background-color: #000;
  width: 100%;
  height: 100%;
  position: relative;
  //padding-top: 56.25%; /* default aspect ratio for video player */
  ${({ aspectRatio }) => css`
    //padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%);
    //--aspect-ratio: ${aspectRatio.split(":").join("/")};
  `}
  video {
    //position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  ${({ isMobile }) => {
    return (
      isMobile &&
      css`
        .shaka-controls-button-panel {
          display: flex;
          justify-content: space-between;
        }
        /*.shaka-small-play-button {
          font-size: 36px;
          position: relative;
          top: -2px;
          left: -10px;
        }
        .shaka-bottom-controls {
          position: relative;
          bottom: 20px;
        }
        .shaka-controls-button-panel {
          display: flex;
          width: 100%;
          justify-content: space-between;
          position: relative;
          top: 23px;
        }
        .shaka-mute-button {
          font-size: 70px;
          height: auto;
        }
        .shaka-range-container {
          bottom: 13px;
          top: auto;
          width: 84%;
          position: absolute;
          left: 46%;
          transform: translateX(-50%);
        }*/
      `
    );
  }}
`;

function ShakaPlayerInner(
  {
    src,
    config,
    chromeless,
    className,
    options,
    aspectRatio,
    parentSize,
    ...rest
  },
  ref
) {
  const uiContainerRef = React.useRef(null);
  const videoRef = React.useRef(null);

  const [player, setPlayer] = React.useState(null);
  const [ui, setUi] = React.useState(null);
  const detectMobile = useMobileDetect();
  const duration = useRef(null);
  const isMobile = useMemo(() => {
    return detectMobile.isMobile();
  }, [detectMobile]);
  const [muted, setMuted] = useState(false);

  const { handleVideoPlay, handleVideoPause, handleVideoEnd, handlePlaying } =
    useInforming({
      duration,
      options: options,
      mobile: isMobile,
    });

  useEffect(() => {
    if (aspectRatio) {
      //onAspectRatio(aspectRatio);
    }
  }, [aspectRatio]);

  React.useEffect(() => {
    const player = new shaka.Player(videoRef.current);
    setPlayer(player);

    let ui;
    if (!chromeless) {
      const ui = new shaka.ui.Overlay(
        player,
        uiContainerRef.current,
        videoRef.current
      );
      window.$(".shaka-overflow-menu-button").html("settings");
      window
        .$(".shaka-back-to-overflow-button .material-icons-round")
        .html("arrow_back_ios_new");

      setUi(ui);
    }

    return () => {
      player.destroy();
      if (ui) {
        ui.destroy();
      }
    };
  }, []);

  function onAdaptation(event) {
    duration.current = player.seekRange().end;
    //console.log(player.getVariantTracks());
  }

  // Keep shaka.Player.configure in sync.
  React.useEffect(() => {
    if (player && config) {
      let _config = {
        ...config,
        forceLandscapeOnFullscreen: false,
        fadeDelay: 0,
      };
      player.configure(config);
    }
  }, [player, config]);

  useEffect(() => {
    if (ui && isMobile) {
      if (isMobile) {
        config = {
          addSeekBar: true,
          singleClickForPlayAndPause: false,
          addBigPlayButton: false,
          controlPanelElements: ["play_pause", "time_and_duration"],
          //controlPanelElements: ["mute", ""],
        };
        ui.configure(config);
      }
    }
    if (ui && !isMobile) {
      const config = {
        addBigPlayButton: true,
        overflowMenuButtons: ["quality"],
        fadeDelay: 0,
      };
      ui.configure(config);
    }
  }, [ui, isMobile]);

  // Load the source url when we have one.
  React.useEffect(() => {
    if (player && src) {
      try {
        player.load(src);
        player.addEventListener("adaptation", onAdaptation);

        console.log("loaded: ", src);
      } catch (e) {
        console.log("load error: ", e);
      }
    }
  }, [player, src]);

  // Define a handle for easily referencing Shaka's player & ui API's.
  React.useImperativeHandle(
    ref,
    () => ({
      get player() {
        return player;
      },
      get ui() {
        return ui;
      },
      get videoElement() {
        return videoRef.current;
      },
    }),
    [player, ui]
  );

  const getVideoProps = () => {
    if (isMobile) {
      return { muted: true };
    }
    return {};
  };

  const handleClickMuteIos = () => {
    setMuted((prev) => !prev);
  };

  useEffect(() => {
    if (isMobile && videoRef?.current) {
      const video = videoRef.current;
      video.muted = muted;
    }
  }, [muted, videoRef, isMobile]);

  return (
    <Wrapper
      aspectRatio={aspectRatio}
      isMobile={true}
      ref={uiContainerRef}
      className={"youtube-theme " + className}
    >
      {isMobile && <MobileMute mute={muted} onClick={handleClickMuteIos} />}
      <video
        playsInline
        onPlay={() => {
          const controls = uiContainerRef.current.querySelector(
            ".shaka-controls-container"
          );
          // controls.setAttribute("shown", false);
          handleVideoPlay();
        }}
        onPlaying={handlePlaying}
        onPause={(event) => handleVideoPause(event, videoRef.current)}
        onEnded={(event) => {
          handleVideoEnd(event, { video: videoRef.current });
        }}
        ref={videoRef}
        {...rest}
      />
    </Wrapper>
  );
}

const Player = React.forwardRef(ShakaPlayerInner);

export const HTMLPlayer = ({ options }) => {
  const player = useRef();
  const [srcHLS, setSrcHLS] = useState(null);
  const [srcDash, setSrcDash] = useState(null);
  const [poster, setPoster] = useState("");
  const [quality, setQuality] = useState("Auto");
  const detectMobile = useMobileDetect();
  const [qualitySetting, setQualitySetting] = useState(""); // ui
  const [qualitiesFromManifest, setQualitiesFromManifest] = useState([]);
  const availableQualities = useRef([]); // from player
  const [aspectRatio, setAspectRatio] = useState("");
  const [pW, pH] = useSize(options.container);

  useLayoutEffect(() => {
    //options.container.style = "height: 100%; width: 100%";
  }, [options.container]);

  useEffect(
    async function Init() {
      if (Array.isArray(options?.source?.sources)) {
        const sourceUrl = options.source.sources[0].src;
        setSrcDash(sourceUrl);
        let poster = options.source.sources[0].poster;
        if (options.source.sources[0].type === "video/dash") {
          const mpd = await parseMPD(sourceUrl);
          let qualities = await getQualitiesFromMPD(mpd);
          qualities = qualities
            .slice(1, qualities.length)
            .sort((a, b) => a.width - b.width);
          //console.log(qualities);
          setQualitiesFromManifest(qualities);
          const hasAudio = videoHasAudio(mpd);
          const link = await getHLSlink(qualities, sourceUrl, hasAudio);
          const aspectRatio = getAspectRatio({
            width: qualities[0].width,
            height: qualities[0].height,
          });
          if (!poster) {
            poster = getPosterUrl(
              sourceUrl,
              qualities[qualities.length - 1].width
            );
          }
          setAspectRatio(aspectRatio);
          setSrcHLS(link);
          setPoster(poster);
        } else {
          //setSrcHLS(sourceUrl);
          setPoster(poster);
        }
      }
    },
    [options]
  );

  return (
    <Fragment>
      {srcHLS && (
        <Player
          ref={player}
          src={srcHLS}
          poster={poster}
          aspectRatio={aspectRatio}
          chromeless={false}
          options={options}
          config={{
            streaming: {
              useNativeHlsOnSafari: false,
            },
          }}
        />
      )}
    </Fragment>
  );
};

//export default React.forwardRef(ShakaPlayer);
