import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Spring, animated, useSpring, config } from "react-spring";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

const Wrapper = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 50px;
  right: 0;
  z-index: 2;
  ${({ initialFix }) => {
    return (
      initialFix &&
      css`
        button {
          opacity: 0 !important;
        }
      `
    );
  }}//pointer-events: none;
`;

const InnerButton = styled(animated.button)`
  background: none;
  border: none;
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  svg {
    width: 80%;
    height: 80%;
    color: #fff;
  }
`;

export const MobileMute = ({ mute = true, onClick }) => {
  const [stateAnimation, setStateAnimation] = useState(false);
  const shown = useSpring({
    opacity: stateAnimation ? 1 : 0,
    transform: stateAnimation ? "scale(1)" : "scale(0)",
    from: { opacity: 0, transform: "scale(0)" },
    onRest: (e) => {
      if (e.finished) {
        setStateAnimation(false);
      }
    },
  });

  const handleClick = () => {
    setStateAnimation((prev) => !prev);
    onClick();
  };

  return (
    <Wrapper onClick={handleClick}>
      <InnerButton style={shown}>
        {mute ? <VolumeOffIcon /> : <VolumeUpIcon />}
      </InnerButton>
    </Wrapper>
  );
};
